import axios from 'axios'

const API_ENDPOINT = 'link-user-groups'

export default {

    all(data) {
        return axios.get(API_ENDPOINT, {params: data})
    },

    find(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    create(model) {
        return axios.post(API_ENDPOINT, model)
    },

    update(model) {
        return axios.put(API_ENDPOINT + '/' + model.id, model)
    },

    delete(id) {
        return  axios.delete(API_ENDPOINT + '/' + id)
    },

    deleteByUser(link_id, user_id) {
        return  axios.delete(`link-user-groups-delete-by-user/link/${link_id}/user/${user_id}`)
    },
    deleteByGroup(link_id, group_id) {
        return  axios.delete(`link-user-groups-delete-by-group/link/${link_id}/group/${group_id}`)
    },

}
