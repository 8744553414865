<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <!-- <img src="logo.jpeg" class="image"> -->
            <!-- <img src="logo2.jpeg" class="image"> -->
            <img src="logo3.png" class="image">
            <p><h5>{{ config.appName }}</h5></p>
        </div>
        <login-form @submit="onSubmit" :errors="authErrors" :loading="loading"></login-form>
    </el-card>
</template>

<script>
    import LoginForm from './LoginForm'
    export default {
        name: 'Login',
        components: {LoginForm},
        data() {
            return {
                authErrors: {},
                loading: false,
            }
        },
        methods: {
            onSubmit(loginData) {
                this.loading = true
                this.$auth
                    .login({
                        data: loginData,
                    })
                    .then(() => {
                        // success
                    }, error => {
                        if (error.response.status === 422)
                            this.authErrors = error.response.data.errors
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<style scoped lang="scss">

img {
    vertical-align: middle;
    border-style: none;
    width: 100px;
}

</style>
