<template>
    <section id="dashboard">

    </section>
</template>

<script>
    export default {
        name: "Dashboard",
        data: () => ({

        }),
    }
</script>

<style lang="scss" scoped>

</style>
