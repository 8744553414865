import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        linkUserGroups: [],
        linkUserGroupsMeta: [],
        linkUserGroupsLoading: true,
    },
    getters: {
        linkUserGroups: state => state.linkUserGroups,
        linkUserGroupsMeta: state => state.linkUserGroupsMeta,
        linkUserGroupsLoading: state => state.linkUserGroupsLoading,
    },
    mutations: {
        [types.LINK_USER_GROUP_OBTAIN](state, linkUserGroups) {
            state.linkUserGroups = linkUserGroups
        },
        [types.LINK_USER_GROUP_CLEAR](state) {
            state.linkUserGroups = []
        },
        [types.LINK_USER_GROUP_SET_LOADING](state, loading) {
            state.linkUserGroupsLoading = loading
        },
        [types.LINK_USER_GROUP_META](state, meta) {
            state.linkUserGroupsMeta = meta
        },
    },
    actions
}
