import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        auditings: [],
        auditingsMeta: [],
        auditingsLoading: true,
    },
    getters: {
        auditings: state => state.auditings,
        auditingsMeta: state => state.auditingsMeta,
        auditingsLoading: state => state.auditingsLoading,
    },
    mutations: {
        [types.AUDITING_OBTAIN](state, auditings) {
            state.auditings = auditings
        },
        [types.AUDITING_CLEAR](state) {
            state.auditings = []
        },
        [types.AUDITING_SET_LOADING](state, loading) {
            state.auditingsLoading = loading
        },
        [types.AUDITING_META](state, meta) {
            state.auditingsMeta = meta
        },
    },
    actions
}
