import Dashboard from "./components/Dashboard";

export const routes = [
    // {
    //     path: '/dashboard',
    //     name: 'Dashboard',
    //     component: Dashboard,
    //     iconCls: 'el-icon-menu'
    // },
];
