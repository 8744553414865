<template>
    <aside>
        <el-menu :default-active="$route.path" class="el-menu-vertical-demo"
                 @open="handleOpen"
                 @close="handleClose"
                 @select="handleSelect"
                 router
                 :collapse="coreIsCollapsed">
            <template v-for="(item,index) in admin_routes">
                <el-menu-item :route="item" v-if="!item.children && !item.hidden && isAdmin(item)" :index="item.path">
                    <i :class="item.iconCls || 'el-icon-s-claim'"></i>
                    <span slot="title">{{ $t(item.name) }}</span>
                </el-menu-item>
                <el-submenu :index="index+''" v-if="item.children && !item.hidden">
                    <template slot="title">
                        <i :class="item.iconCls || 'el-icon-s-claim'"></i>
                        <span slot="title">{{ item.name }}</span>
                    </template>
                    <el-menu-item v-for="child in item.children"
                                  v-if="!child.hidden"
                                  :route="child"
                                  :index="child.path"
                                  :key="child.path">
                        <span slot="title">{{ child.name }}</span>
                    </el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
    </aside>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Sidebar",
        components: {},
        data: () => ({

        }),
        methods: {
            isAdmin(item){

                let isAdmin = false

                if(this.$auth.user().groups.filter((element) => {
                    return element.id == 1;
                }).length > 0) {
                    isAdmin = true;
                }

                switch (item.path) {

                    case '/linkUserGroups':

                        if(!isAdmin){
                            return false
                        }

                        break;
                    case '/users':

                        if(!isAdmin){
                            return false
                        }

                        break;
                    case '/userGroups':

                        if(!isAdmin){
                            return false
                        }

                        break;

                    case '/redirections':

                        if(!isAdmin){
                            return false
                        }

                        break;

                    case '/integrations':

                        if(!isAdmin){
                            return false
                        }

                        break;

                    case '/auditings':

                        if(!isAdmin){
                            return false
                        }

                        break;

                    default:
                        break;
                }

                return true

            },
            menuItemIsAvailable(item) {

                let allowed = !item.meta.hidden;
                if (allowed  && item.meta && item.meta.auth) {
                    allowed = this.$auth.check(item.meta.auth);
                }
                return allowed;
            },
            handleOpen() {
                //console.log('handleOpen');
            },
            handleClose() {
                //console.log('handleClose');
            },
            handleSelect: function (a, b) {
            },
        },
        computed: {
            ...mapGetters(['coreIsCollapsed']),
            admin_routes() {
                return this.$router.options.routes[0].children
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
