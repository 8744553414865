import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        links: [],
        linksMeta: [],
        linksLoading: true,
    },
    getters: {
        links: state => state.links,
        linksMeta: state => state.linksMeta,
        linksLoading: state => state.linksLoading,
    },
    mutations: {
        [types.LINK_OBTAIN](state, links) {
            state.links = links
        },
        [types.LINK_CLEAR](state) {
            state.links = []
        },
        [types.LINK_SET_LOADING](state, loading) {
            state.linksLoading = loading
        },
        [types.LINK_META](state, meta) {
            state.linksMeta = meta
        },
    },
    actions
}
