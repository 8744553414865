const globalDateTimeDBFormat = "YYYY-MM-DD HH:mm:ss";
const globalDateFormat = "DD/MM/YYYY";
const globalDateTimeFormat = "DD/MM/YYYY HH:mm:ss";
export default {
    data: () => ({
        globalDateFormat: globalDateFormat,
        globalDateTimeFormat: globalDateTimeFormat,
        globalDateTimeDBFormat: globalDateTimeDBFormat,
        globalPageSize: 10
    }),
    filters: {
        capitalize: function(value) {
            if (!value) return "";
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        baseUrlLink: function(value) {
            if (!value) return "";
            value = value.toString();
            return `${window.config.base_url}/to/${value}`;
        },
        link: function(value) {
            if (!value) return "";
            value = value.toString();
            if (value.search("http") == 0) return value;
            return `https://${value}`;
        },
        decodeHtml: function(html) {
            return JSON.stringify(JSON.parse(html));
        }
    },
    methods: {
        /**
         * @return {string}
         */
        GlobalFormatDate(datetime) {
            return moment(datetime, globalDateTimeDBFormat).format(
                globalDateFormat
            );
        },
        /**
         * @return {string}
         */
        GlobalFormatTime(datetime) {
            return moment(datetime, globalDateTimeDBFormat).format(
                globalDateTimeFormat
            );
        },
        getPermissionsDisabledProject(type, responsible_user) {
            if (this.isAdmin()) {
                return false; // se admin libera botões
            }

            if (this.$auth.user().groups.length > 0) {
                switch (type) {
                    case "insert":
                        let insert_user_group =
                            this.$auth.user().groups.filter(element => {
                                // Grupos do usuário que tem permissao cadastrar
                                return element.insert == 1;
                            }).length > 0;

                        return !insert_user_group; // false não bloqueia o botão

                        break;
                    case "edit":
                        let edit_user_group =
                            this.$auth.user().groups.filter(element => {
                                // Grupos do usuário que tem permissao editar
                                return element.edit == 1;
                            }).length > 0;

                        if (
                            responsible_user &&
                            edit_user_group &&
                            responsible_user === this.$auth.user().id
                        ) {
                            return false; // libera botão
                        } else {
                            return true; // bloqueia botão
                        }

                        break;
                    case "delete":
                        let delete_user_group =
                            this.$auth.user().groups.filter(element => {
                                // Grupos do usuário que tem permissao editar
                                return element.delete == 1;
                            }).length > 0;

                        if (
                            responsible_user &&
                            delete_user_group &&
                            responsible_user === this.$auth.user().id
                        ) {
                            return false; // libera botão
                        } else {
                            return true; // bloqueia botão
                        }

                        break;
                }
            }

            return true; // Desabilita o campo
        },
        getPermissionsDisabled(type, usersGroups) {
            if (this.isAdmin()) {
                return false; // se admin libera botões
            }

            if (this.$auth.user().groups.length > 0) {
                switch (type) {
                    case "insert":
                        let insert_user_group =
                            this.$auth.user().groups.filter(element => {
                                // Grupos do usuário que tem permissao cadastrar
                                return element.insert == 1;
                            }).length > 0;

                        return !insert_user_group; // false não bloqueia o botão

                        break;
                    case "edit":
                        let edit_user_group =
                            this.$auth.user().groups.filter(element => {
                                // Grupos do usuário que tem permissao editar
                                return element.edit == 1;
                            }).length > 0;

                        let group_in_link = false;

                        for (const key in this.$auth.user().groups) {
                            group_in_link =
                                usersGroups.filter(element => {
                                    // Grupos do usuário que tem permissao editar
                                    return (
                                        element.group_id ==
                                        this.$auth.user().groups[key].id
                                    );
                                }).length > 0;

                            if (group_in_link) {
                                continue;
                            }
                        }

                        if (edit_user_group && group_in_link) {
                            return false; // Não desabilita o campo
                        } else {
                            let user_in_link =
                                usersGroups.filter(element => {
                                    // Grupos do usuário que tem permissao editar
                                    if (
                                        element.user_id ==
                                            this.$auth.user().id &&
                                        element.edit
                                    ) {
                                        return true;
                                    }
                                }).length > 0;

                            if (user_in_link) {
                                return false; // Não desabilita o campo
                            }
                        }

                        break;
                    case "delete":
                        let delete_user_group =
                            this.$auth.user().groups.filter(element => {
                                // Grupos do usuário que tem permissao editar
                                return element.delete == 1;
                            }).length > 0;

                        let group_in_link_delete = false;

                        for (const key in this.$auth.user().groups) {
                            group_in_link_delete =
                                usersGroups.filter(element => {
                                    // Grupos do usuário que tem permissao editar
                                    return (
                                        element.group_id ==
                                        this.$auth.user().groups[key].id
                                    );
                                }).length > 0;

                            if (group_in_link_delete) {
                                continue;
                            }
                        }

                        if (delete_user_group && group_in_link_delete) {
                            return false; // Não desabilita o campo
                        } else {
                            let user_in_link_delete =
                                usersGroups.filter(element => {
                                    // Grupos do usuário que tem permissao editar
                                    if (
                                        element.user_id ==
                                            this.$auth.user().id &&
                                        element.delete
                                    ) {
                                        return true;
                                    }
                                }).length > 0;

                            if (user_in_link_delete) {
                                return false; // Não desabilita o campo
                            }
                        }

                        break;
                }
            }

            return true; // Desabilita o campo
        },
        isAdmin() {
            if (
                this.$auth.user().groups.filter(element => {
                    return element.id === 1;
                }).length > 0
            ) {
                return true;
            }

            return false;
        },
        abrevia(str) {
            const nome = str.replace(/\s+/gi, " ").trim();
            var array_nome = nome.split(" ");
            return array_nome
                .map((parte, index, nomes) =>
                    index == 0 ? `${parte[0]}.` : parte
                )
                .join(" ");
        }
    }
};
