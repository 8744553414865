/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/linkUserGroups',
        name: 'link vs user vs group',
        component: page('LinkUserGroupList'),
        hidden: true
    },
    {
        path: '/linkUserGroups/:id',
        name: 'Show LinkUserGroup',
        component: page('LinkUserGroupView'),
        hidden: true
    }
]
