import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        userGroups: [],
        userGroupsMeta: [],
        userGroupsLoading: true,
    },
    getters: {
        userGroups: state => state.userGroups,
        userGroupsMeta: state => state.userGroupsMeta,
        userGroupsLoading: state => state.userGroupsLoading,
    },
    mutations: {
        [types.USER_GROUP_OBTAIN](state, userGroups) {
            state.userGroups = userGroups
        },
        [types.USER_GROUP_CLEAR](state) {
            state.userGroups = []
        },
        [types.USER_GROUP_SET_LOADING](state, loading) {
            state.userGroupsLoading = loading
        },
        [types.USER_GROUP_META](state, meta) {
            state.userGroupsMeta = meta
        },
    },
    actions
}
