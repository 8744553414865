/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/categories',
        name: 'Categorias',
        component: page('CategoryList'),
        iconCls: 'el-icon-s-order'
    },
    {
        path: '/categories/:id',
        name: 'Show Category',
        component: page('CategoryView'),
        hidden: true
    }
]
