<template>
    <el-col :span="24" class="header">
        <el-col style="padding-left:0" :span="10" class="logo" :class="coreIsCollapsed?'logo-collapse-width':'logo-width'">
            <!-- <i class="el-icon-link"><span>{{ coreIsCollapsed ? '' : abrevia(config.appName)}}</span></i> -->

            <!-- <img src="logo.png" class="image"> -->
            <!-- <img src="logo2.jpeg" class="image"> -->
            <img src="logo3.png" class="image">
            <h6 v-if="!coreIsCollapsed">{{config.appName}}</h6>

        </el-col>
        <el-col :span="10">
            <div class="tools" @click.prevent="collapse">
                <i class="fa fa-align-justify"></i>
            </div>
        </el-col>
        <el-col :span="4" class="userinfo">
            <el-dropdown trigger="hover">
                <span class="el-dropdown-link userinfo-inner"><img :src="sysUserAvatar"/> {{sysUserName}}</span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="logout" dusk="logout">{{ $t('auth.logout.title') }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </el-col>
    </el-col>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import {TOGGLE_COLLAPSE} from "../store/types";

    export default {
        data: () => ({
            sysUserName: '',
            sysUserAvatar: '',
        }),
        methods: {
            ...mapMutations([TOGGLE_COLLAPSE]),
            logout: function () {
                this.$confirm(this.$t('auth.logout_confirm.text'), this.$t('auth.logout_confirm.title'), {
                    confirmButtonText: this.$t('auth.logout_confirm.button_ok'),
                    cancelButtonText: this.$t('auth.logout_confirm.button_cancel'),
                }).then(() => {
                    this.$auth.logout()
                })
            },
            collapse() {
                this[TOGGLE_COLLAPSE]()
            },
        },
        mounted() {
            const user = this.$auth.user();

            if (user) {
                this.sysUserName = user.name || '';
                // this.sysUserAvatar = user.avatar || '';
            }
            this.sysUserAvatar = 'user.png';
        },
        computed: {
            ...mapGetters(['coreIsCollapsed'])
        }
    }
</script>

<style scoped type="scss">

.home-container .header .logo img {
    width: 40px;
    float: left;
    margin: 5px 10px 10px 10px;
}
.home-container .header .logo h6 {
    margin: 10px 10px 10px 10px;
    text-align: center;
}

</style>
