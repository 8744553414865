import * as types from './types'
import areaApi from '../api'

export const actions = {
    async [types.AREA_FETCH]({commit}, data = null) {
        commit(types.AREA_SET_LOADING, true)
        const response = await areaApi.all(data)
        commit(types.AREA_OBTAIN, response.data.data)
        commit(types.AREA_META, response.data.meta)
        commit(types.AREA_SET_LOADING, false)
    },
}
