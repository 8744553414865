import * as types from './types'
import auditingApi from '../api'

export const actions = {
    async [types.AUDITING_FETCH]({commit}, data = null) {
        commit(types.AUDITING_SET_LOADING, true)
        const response = await auditingApi.all(data)
        commit(types.AUDITING_OBTAIN, response.data.data)
        commit(types.AUDITING_META, response.data.meta)
        commit(types.AUDITING_SET_LOADING, false)
    },
}
