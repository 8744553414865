<template>
    <section id="redirecionamento_show">
        <div class="text-center">{{ redirecionamento.name }}</div>
    </section>
</template>

<script>

    import redirecionamentoApi from "../api"
    import Login from '../../auth/components/Login'

    export default {
        name: "RedirecionamentoView",
        components: {},
        data() {
            return {
                redirecionamento: {},
            }
        },
        methods: {
            async fetchRedirecionamento() {
                const vm = this
                await redirecionamentoApi.to(this.$route.params.code).then(function (response) {
                    if(!response.data){
                        return false
                    }
                    window.location.href = vm.$options.filters.link(response.data);
                })
            },
        },
        mounted() {
            this.fetchRedirecionamento()
        },
    }
</script>

<style lang="scss" scoped>

</style>
