/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/',
        name: 'Projetos',
        component: page('ProjectList'),
        iconCls: 'el-icon-link'
    },
    {
        path: '/projects/:id',
        name: 'Exibir projeto',
        component: page('ProjectView'),
        hidden: true
    }
]
