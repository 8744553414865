import * as types from './types'
import redirectionApi from '../api'

export const actions = {
    async [types.REDIRECTION_FETCH]({commit}, data = null) {
        commit(types.REDIRECTION_SET_LOADING, true)
        const response = await redirectionApi.all(data)
        commit(types.REDIRECTION_OBTAIN, response.data.data)
        commit(types.REDIRECTION_META, response.data.meta)
        commit(types.REDIRECTION_SET_LOADING, false)
    },
}
