import * as types from './types'
import linkApi from '../api'

export const actions = {
    async [types.LINK_FETCH]({commit}, data = null) {
        commit(types.LINK_SET_LOADING, true)
        const response = await linkApi.all(data)
        commit(types.LINK_OBTAIN, response.data.data)
        commit(types.LINK_META, response.data.meta)
        commit(types.LINK_SET_LOADING, false)
    },
}
