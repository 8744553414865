import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        contentStatuses: [],
        contentStatusesMeta: [],
        contentStatusesLoading: true,
    },
    getters: {
        contentStatuses: state => state.contentStatuses,
        contentStatusesMeta: state => state.contentStatusesMeta,
        contentStatusesLoading: state => state.contentStatusesLoading,
    },
    mutations: {
        [types.CONTENT_STATUS_OBTAIN](state, contentStatuses) {
            state.contentStatuses = contentStatuses
        },
        [types.CONTENT_STATUS_CLEAR](state) {
            state.contentStatuses = []
        },
        [types.CONTENT_STATUS_SET_LOADING](state, loading) {
            state.contentStatusesLoading = loading
        },
        [types.CONTENT_STATUS_META](state, meta) {
            state.contentStatusesMeta = meta
        },
    },
    actions
}
