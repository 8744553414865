import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        redirections: [],
        redirectionsMeta: [],
        redirectionsLoading: true,
    },
    getters: {
        redirections: state => state.redirections,
        redirectionsMeta: state => state.redirectionsMeta,
        redirectionsLoading: state => state.redirectionsLoading,
    },
    mutations: {
        [types.REDIRECTION_OBTAIN](state, redirections) {
            state.redirections = redirections
        },
        [types.REDIRECTION_CLEAR](state) {
            state.redirections = []
        },
        [types.REDIRECTION_SET_LOADING](state, loading) {
            state.redirectionsLoading = loading
        },
        [types.REDIRECTION_META](state, meta) {
            state.redirectionsMeta = meta
        },
    },
    actions
}
