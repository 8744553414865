import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        projects: [],
        projectsMeta: [],
        projectsLoading: true,
    },
    getters: {
        projects: state => state.projects,
        projectsMeta: state => state.projectsMeta,
        projectsLoading: state => state.projectsLoading,
    },
    mutations: {
        [types.PROJECT_OBTAIN](state, projects) {
            state.projects = projects
        },
        [types.PROJECT_CLEAR](state) {
            state.projects = []
        },
        [types.PROJECT_SET_LOADING](state, loading) {
            state.projectsLoading = loading
        },
        [types.PROJECT_META](state, meta) {
            state.projectsMeta = meta
        },
    },
    actions
}
