import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        areas: [],
        areasMeta: [],
        areasLoading: true,
    },
    getters: {
        areas: state => state.areas,
        areasMeta: state => state.areasMeta,
        areasLoading: state => state.areasLoading,
    },
    mutations: {
        [types.AREA_OBTAIN](state, areas) {
            state.areas = areas
        },
        [types.AREA_CLEAR](state) {
            state.areas = []
        },
        [types.AREA_SET_LOADING](state, loading) {
            state.areasLoading = loading
        },
        [types.AREA_META](state, meta) {
            state.areasMeta = meta
        },
    },
    actions
}
