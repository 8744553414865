/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/areas',
        name: 'Áreas',
        component: page('AreaList'),
        iconCls: 'el-icon-folder-checked'
    },
    {
        path: '/areas/:id',
        name: 'Show Area',
        component: page('AreaView'),
        hidden: true
    }
]
