import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        integrations: [],
        integrationsMeta: [],
        integrationsLoading: true,
    },
    getters: {
        integrations: state => state.integrations,
        integrationsMeta: state => state.integrationsMeta,
        integrationsLoading: state => state.integrationsLoading,
    },
    mutations: {
        [types.INTEGRATION_OBTAIN](state, integrations) {
            state.integrations = integrations
        },
        [types.INTEGRATION_CLEAR](state) {
            state.integrations = []
        },
        [types.INTEGRATION_SET_LOADING](state, loading) {
            state.integrationsLoading = loading
        },
        [types.INTEGRATION_META](state, meta) {
            state.integrationsMeta = meta
        },
    },
    actions
}
