/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/redirections',
        name: 'Log de redirecionamentos',
        component: page('RedirectionList'),
    },
    {
        path: '/redirections/:id',
        name: 'Show Redirection',
        component: page('RedirectionView'),
        hidden: true
    }
]
