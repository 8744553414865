import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        redirecionamentos: [],
        redirecionamentosMeta: [],
        redirecionamentosLoading: true,
    },
    getters: {
        redirecionamentos: state => state.redirecionamentos,
        redirecionamentosMeta: state => state.redirecionamentosMeta,
        redirecionamentosLoading: state => state.redirecionamentosLoading,
    },
    mutations: {
        [types.REDIRECIONAMENTO_OBTAIN](state, redirecionamentos) {
            state.redirecionamentos = redirecionamentos
        },
        [types.REDIRECIONAMENTO_CLEAR](state) {
            state.redirecionamentos = []
        },
        [types.REDIRECIONAMENTO_SET_LOADING](state, loading) {
            state.redirecionamentosLoading = loading
        },
        [types.REDIRECIONAMENTO_META](state, meta) {
            state.redirecionamentosMeta = meta
        },
    },
    actions
}
