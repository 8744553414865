import enLocale from 'element-ui/lib/locale/lang/pt-br'

export const messages = {
    global: {
        ok: 'Ok',
        cancel: 'Cancelar',
        save: 'Salvar',
        add: 'Adicionar',
        edit: 'Editar',
        delete: 'Excluir',
        new: 'Novo',
        search: 'Pesquisar...',
        unknown_server_error: 'Erro de servidor desconhecido',
        form: {
            rules: {
                required: 'Campo "{fieldName}" é obrigatório',
                email: 'Insira o endereço de e-mail correto',
                min: 'O comprimento do campo "{fieldName}" deve ter mais de {attribute} caracteres',
                max: 'O comprimento do campo "{fieldName}" deve ser inferior a {attribute} caracteres',
                password_repeat: {
                    different: 'Senhas diferentes',
                }
            }
        },
    },
    auth: {
        token_expired_alert_title: 'Sessão expirada!',
        token_expired_alert_text: 'Faça login novamente para continuar.',
        verification: {
            resend_link: 'Reenviar verificação',
            resend_title: 'Reenviar verificação',
            resend_button: 'Enviar',
            failed: 'O link de verificação é inválido.',
        },
        resend_verification_link: 'Reenviar verificação por email',
        login: {
            title: 'Login',
            submit_button: 'Entrar',
            email_label: 'Email',
            password_label: 'Senha',
        },
        logout: {
            title: 'Sair',
        },
        register: {
            title: 'Registrar',
            name_label: 'Nome',
            email_label: 'Email',
            password_label: 'Senha',
            password_confirmation_label: 'Confirmação de senha',
            submit_button: 'Registrar',
            success: 'Obrigado por se registrar'
        },
        logout_confirm: {
            title: 'Confirme LogOut',
            text: 'Você será desconectado',
            button_ok: 'Ok',
            button_cancel: 'Cancelar',
        }
    },
    setting: {
        profile: {
            name: 'Nome',
            email: 'Email',
        }
    },
    ...enLocale
}
