import * as types from './types'
import categoryApi from '../api'

export const actions = {
    async [types.CATEGORY_FETCH]({commit}, data = null) {
        commit(types.CATEGORY_SET_LOADING, true)
        const response = await categoryApi.all(data)
        commit(types.CATEGORY_OBTAIN, response.data.data)
        commit(types.CATEGORY_META, response.data.meta)
        commit(types.CATEGORY_SET_LOADING, false)
    },
}
