import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        categories: [],
        categoriesMeta: [],
        categoriesLoading: true,
    },
    getters: {
        categories: state => state.categories,
        categoriesMeta: state => state.categoriesMeta,
        categoriesLoading: state => state.categoriesLoading,
    },
    mutations: {
        [types.CATEGORY_OBTAIN](state, categories) {
            state.categories = categories
        },
        [types.CATEGORY_CLEAR](state) {
            state.categories = []
        },
        [types.CATEGORY_SET_LOADING](state, loading) {
            state.categoriesLoading = loading
        },
        [types.CATEGORY_META](state, meta) {
            state.categoriesMeta = meta
        },
    },
    actions
}
